# Samuraiworks JS (effect all)
###<![CDATA[###
"use strict"
# sw ------------- chk Modern PC (not IE) SMP 強化版 IE11 add
userAgent = window.navigator.userAgent.toLowerCase()
appVersion = window.navigator.appVersion.toLowerCase()
uaName = "unknown"; ios = undefined; uaNameVer = undefined
unless userAgent.indexOf("msie") is -1
  uaName = "ie"
  unless appVersion.indexOf("msie 6.") is -1       then uaName = "ie6"
  else unless appVersion.indexOf("msie 7.") is -1  then uaName = "ie7"
  else unless appVersion.indexOf("msie 8.") is -1  then uaName = "ie8"
  else unless appVersion.indexOf("msie 9.") is -1  then uaName = "ie9"
  else unless appVersion.indexOf("msie 10.") is -1 then uaName = "ie10"
else unless userAgent.indexOf("trident/7") is -1   then uaName = "ie11"
else unless userAgent.indexOf("chrome") is -1      then uaName = "chrome"
else unless userAgent.indexOf("ipad") is -1
  ios = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
  uaNameVer = [parseInt(ios[1], 10), parseInt(ios[2], 10), parseInt(ios[3] or 0, 10)]
  uaName = "ipad"
else unless userAgent.indexOf("ipod") is -1
  ios = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
  uaNameVer = [parseInt(ios[1], 10), parseInt(ios[2], 10), parseInt(ios[3] or 0, 10)]
  uaName = "ipod"
else unless userAgent.indexOf("iphone") is -1
  ios = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
  uaNameVer = [parseInt(ios[1], 10), parseInt(ios[2], 10), parseInt(ios[3] or 0, 10)]
  uaName = "iphone" # + uaNameVer
else unless userAgent.indexOf("android") is -1    then uaName = "android"
else unless userAgent.indexOf("safari") is -1     then uaName = "safari"
else unless userAgent.indexOf("gecko") is -1      then uaName = "gecko"
else unless userAgent.indexOf("opera") is -1      then uaName = "opera"
else uaName = "mobile"  unless userAgent.indexOf("mobile") is -1
# sw ------------- sp&tablet add
getDevice = do ->
  uaSpTab = navigator.userAgent
  if uaSpTab.indexOf('iPhone') > 0 or uaSpTab.indexOf('iPod') > 0 or uaSpTab.indexOf('Android') > 0 and uaSpTab.indexOf('Mobile') > 0 then 'sp'
  else if uaSpTab.indexOf('iPad') > 0 or uaSpTab.indexOf('Android') > 0 then 'tablet'


do ($ = jQuery, win = @window, doc = @document) ->

  # do it init Assy
  $win = $(win)
  $doc = $(doc)
  ### { only these can be accessed from outside 21s ###
  swns = {
    # 日付を返す
    theDate : ->
      today = new Date()
      day = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
      y = today.getFullYear() + ""
      t = today.getMonth()+1
      d = today.getDate()
      w = today.getDay()
      h = today.getHours() #+9
      m = today.getMinutes()
      s = today.getSeconds()
      if(t < 10)then t ="0"+t
      if(d < 10)then d ="0"+d
      if(h < 10)then h ="0"+h
      if(m < 10)then m ="0"+m
      if(s < 10)then s ="0"+s
      #jst = "#{y}/#{t}/#{d}(#{day[w]})#{h}:#{m}:#{s}"
      return "#{y.substr(2, 2)}#{t}#{d}:#{h}#{m}:#{s}"
      # add Time (UTC to JST)
    # トップに戻る
    goToTop : ->
      # --------------------- GoToTop
      $("body").append "<p id='goto-top'></p>"
      $("#goto-top").hide()
      #少したったら消したいから。
      funcTimeout = -> $("#goto-top").fadeOut()
      $.timeout = (time) ->
        $.Deferred((dfd) -> setTimeout dfd.resolve, time ).promise()
      $win.on "scroll", ->
        if $(@).scrollTop() > 191 then $("#goto-top").stop(true, true).fadeIn()
        else $("#goto-top").fadeOut()
      $("#goto-top").on "click", (e) ->
        e.preventDefault()
        $("body,html").animate
          scrollTop: 0
        , 200
    # サイドにおすすめを
    showSideMenu : ->
      #少したったら消したいから。
      funcTimeout = -> $("#showSideMenu").fadeOut()
      $.timeout = (time) ->
        $.Deferred((dfd) -> setTimeout dfd.resolve, time ).promise()
      $win.on "scroll", ->
        if $(@).scrollTop() > 234 then $("#showSideMenu").stop(true, true).fadeIn()
        else $("#showSideMenu").fadeOut()
      $("#goto-top").on "click", (e) ->
        e.preventDefault()
  }
  #win.__SWNS = swns
  ### only these can be accessed from outside } ###

  ###画像プリローダーAssy###
  preloader = (images) ->   # $.preLoadImages Fixed
    $(images).each -> (new Image()).src = @
  $ -> preloader([
    "/img-new/_androidDummy.gif"
    "/img-new/footRogo.png"
    "/img-new/topRogo.png"
    "/img-new/topGroup.png"
    "/img-new/up-arrow.png"
    "/img2017/hdr.png"
    "/img2017/fotolia_a.jpg"
    "/img2017/fotolia_a-sp.jpg"
    "/img2017/fotolia_b.jpg"
    "/img2017/fotolia_b-sp.jpg"
    "/img2017/fotolia_c.jpg"
    "/img2017/fotolia_c-sp.jpg"
    "/img-new/fmh2bg2.png"
    "/img-new/fmh2bg3.png"
    "/img-new/mast-form.png"
  ])

  ###ロールオーバーAssy###
  #------- image rollover function (fix cFs 1.7.1)
  imgRollover = (target) ->
    outImgName = "_ovoff"
    overImgName = "_ovon"
    targetArea = $("" + target + "")
    imgTagData = $("img,input", targetArea)
    reOverImg = new RegExp(outImgName + "+(.[a-z]+)$")
    reOutImg = new RegExp(overImgName + "+(.[a-z]+)$")
    imgTagData.each (i) ->
      if $(this).attr("src")?
        if $(this).attr("src").match(reOverImg)
          $("<img>").attr("src", $(this).attr("src").replace(reOverImg, overImgName + "$1"))
          $(this).bind "mouseover", ->
            $(this).attr("src", $(this).attr("src").replace(reOverImg, overImgName + "$1"))
          $(this).bind "mouseout", ->
            $(this).attr("src", $(this).attr("src").replace(reOutImg, outImgName + "$1"))


  ###each popup(ChildList)###
  eachPopUpList = (popFrom_UL_class) ->
    eachTimeout = []
    target = $(popFrom_UL_class) # ul#nav ul
    target.each (i) ->
      $this = $(this)
      $this.parent().hover (->
        $(".sp #dummyClick, .tablet #dummyClick").show()
        target.hide()
        j = 0
        while j < eachTimeout.length
          clearTimeout eachTimeout[j]
          j++
        $this.show()
      ),(->
        eachTimeout[i] = setTimeout((->
          $(".sp #dummyClick, .tablet #dummyClick").hide()
          $this.hide()
        ), 369) # 消えるまでの待ち時間
      ) # /hover

  ### plugin Type BlockLink ###
  $.fn.blocklinkAssy = () ->
    elements = @
    #settings = $.extend({target},optObj)
    elements.on("click", (e) ->
      e.preventDefault()
      window.open $(@).find("a").attr("href"), $(@).find("a").attr("target") || "_self"
    ).css cursor: "pointer"


  ### media-queries ###
  $win.on 'load resize', ->
    # モダンブラウザでは「pc」という文字列が取得できるが、
    # IEでは「"~"」と「"」が付与されて取得されてしまうため「"」を除去しつつ取得する。
    layout = $('.jsc-media-queries').css('font-family').replace(/"/g, '')
    if layout == 'no-media-query'
      $("body").addClass("no-media-query")
      $("body").removeClass("in-media-query")
    else if layout == 'in-media-query'
      $("body").addClass("in-media-query")
      $("body").removeClass("no-media-query")

  ### animateCallback ###
  # http://takustaqu.hatenablog.com/entry/2014/03/02/025558
  # http://qiita.com/nekoneko-wanwan/items/e8114c6e34d2950a7b28
  # animationstart（delay完了後）
  $.fn.animateStartCallback = (callback) ->
    alias = 'animationstart webkitAnimationStart'
    @each ->
      $(this).on alias, ->
        #$(this).off alias    # sw on/off(unbind is .one())
        return callback.call this
      return
  $.fn.animationIterationCallback = (callback) ->
    alias = 'animationiteration webkitAnimationIteration'
    @each ->
      $(this).on alias, ->
        #$(this).off alias
        return callback.call this
      return
  $.fn.animateEndCallback = (callback) ->
    alias = 'animationend webkitAnimationEnd'
    #alias = 'animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd'
    @each ->
      $(this).on alias, ->
        #$(this).off alias
        return callback.call this
      return
  ### transitionCallback ###
  $.fn.transitionStartCallback = (callback) ->
    alias = 'transitionstart webkitTransitionStart'
    @each ->
      $(this).bind alias, ->
        #$(this).unbind alias
        return callback.call this
      return
  $.fn.transitionEndCallback = (callback) ->
    alias = 'webkitTransitionEnd transitionend'
    #alias = 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend'
    @each ->
      $(this).bind alias, ->
        $(this).unbind alias
        return callback.call this
      return

  ### 各ページDRF読み込み用 ###
  # Do it DRF
  $ ->
    ### testVH fixup change animation callback 最初の「cName1」はとれないけど、いいでしょう ###
    $win.on "load", ()->
      if $("#testVH").length
        $("#testVH .sm-slider div.imgArea").each ->
          $(@).animateStartCallback(->
            classes = $(@).parent().attr("class")
            #console?.info "animateStartCallback", classes       #------- sw Log --(´･_･`)
            $(@).parent().parent().parent().removeClass()
            $(@).parent().parent().parent().addClass("#{classes}")
          )
          $(@).animationIterationCallback(->
            classes = $(@).parent().attr("class")
            #console?.info "animationIterationCallback", classes       #------- sw Log --(´･_･`)
            $(@).parent().parent().parent().removeClass()
            $(@).parent().parent().parent().addClass("#{classes}")
          )
        #end

    ### BlockLink Assy ###
    $(".hNavi").blocklinkAssy()
    ### initRollOvers ###
    imgRollover("body")
    ###トップに戻る Assy###
    swns.goToTop()
    ###showSideMenu Assy###
    #swns.showSideMenu()  if $("#container").hasClass("hasSidemenu")
    ### HeaderMenu POPUP (popFrom_class, popTo_class) ###
    eachPopUpList("ul#nav ul")


    ### Mobile support ###
    $("body").addClass("#{uaName}")
    if getDevice == 'sp' then $("body").addClass("sp")
    else if getDevice == 'tablet' then $("body").addClass("tablet")

    ###each popup(Mobile support) sw ダミークリックに移行... #dummyClick
    $("div.query-close").on "click", (e)->
      if $("body.in-media-query").length
        $(@).parent().parent("ul").parent("li").trigger("mouseleave")
        #e.stopPropagation()
        e.preventDefault()
    ###

    ###UI / UA###
    # H1, H2 wrap <span />
    $("#container").find("h1,h2,h3,h4,h5").each -> $(@).wrapInner("<span />")

    ### sw kaisyatable sippitsu 空白の td から bacol トル(.noBack)###
    $("table.kaisyatable.sippitsu").find("td.calTi").filter(()->
      return $.trim($(this).text()) is '' && $(this).children().length is 0
    ).addClass("noBack")



    ### inview support ###
    $(".inview-list li").on "inview", (e, isInView, visiblePartX, visiblePartY) ->
      if isInView then $(this).stop().addClass "isInView"
      else $(this).stop().removeClass "isInView"

    ### .button-ico (w1024) ###
    $(".button-ico").on "click", (e)->
      $("#rNavi").toggle()
      $(@).toggleClass("on")
      e.preventDefault()


    ### test for .theHome ###
    $(".sp .theHome #testVH").on "click", (e)->
      $el = $("#header .header-bottom")
      if $el.hasClass("on") then $el.css("overflow", "hidden")
      $el.toggleClass("on")
      $el.transitionEndCallback(->
        console.info("transitionEndCallback(+unbind)")
        if $el.hasClass("on") then $el.css("overflow", "inherit") #else $el.css("overflow", "hidden")
      )
      false

    ### # id="motto" ###
    #$("#motto").on "click", (e)-> $("#miru").trigger("click"); console.info("info")


      # global
open01 = (theURL) -> window.open(theURL,'actors','scrollbars=yes,toolbar=0,location=0,directories=0,status=0,menubar=0,resizable=yes,width=650,height=630')
open02 = (theURL) -> window.open(theURL,'actors','scrollbars=yes,toolbar=0,location=0,directories=0,status=0,menubar=0,resizable=yes,width=450,height=630')

### sw GetQuery ###
###*
# URL解析して、クエリ文字列を返す
# @returns {Array} クエリ文字列
###
getUrlVars = ->
  vars = []
  max = 0
  hash = ''
  array = ''
  url = window.location.search
  #todo ?を取り除くため、1から始める。複数のクエリ文字列に対応するため、&で区切る searchなので#はない
  hash = url.slice(1).split('&')
  max = hash.length
  i = 0
  while i < max
    array = hash[i].split('=')
    #keyと値に分割。
    vars.push array[0]
    #末尾にクエリ文字列のkeyを挿入。
    vars[array[0]] = array[1]
    #先ほど確保したkeyに、値を代入。
    i++
  vars

getUrlVarsVal = []
getUrlVarsVal = getUrlVars()


###]]>###
